
import React, { useState } from 'react';
import {Link} from 'react-router-dom';

function VacanciesPage() {

  const vacancies = [
      {
        title: "Web 3 VP of marketing (Equity Cofounder)",
        position: "Part Time",
        remote: "ONSITE",
        level: "Mid-Senior Level",
        status: "NEW"
      },
      {
        title: "Smart Contract Developer",
        position: "Full Time",
        remote: "ONSITE",
        level: "Mid-Senior Level",
        status: "CLOSED"
      },
      {
        title: "Web 3 Project Manager (Equity Cofounder)",
        position: "Part Time",
        remote: "ONSITE",
        level: "Mid-Senior Level",
        status: "CLOSED"
      },
      {
        title: "Blockchain Architect",
        position: "Full Time",
        remote: "REMOTE",
        level: "Mid-Senior Level",
        status: "CLOSED"
      },
      {
        title: "Cosmos SDK Developer",
        position: "Full Time",
        remote: "REMOTE",
        level: "Mid-Senior Level",
        status: "CLOSED"
      },
      {
        title: "Creator On-boarding Manager",
        position: "Part Time",
        remote: "REMOTE",
        level: "Associate",
        status: "CLOSED"
      }
    ]
  return (
    <div className = "screen">
      {
        vacancies.map((item, index)=>(
           <div className = "card">
             <div className = "card-left">
                <p className = "job-title">
                  {item.title}
                </p>
                <div className = "flex">
                  <p className = "mr24">[{item.remote}]</p>
                  <p className = "mr24">[{item.position}]</p>
                  <p className = "mr24">[{item.level}]</p>
                </div>
             </div>
             <div className = "card-right">
                <Link to = {`/job${index+1}`}><button class="btn">Job Details<span class="ribbon" style = {{backgroundColor: item.status == 'NEW' ? 'red':'green'}}>{item.status}</span></button></Link>
             </div>
             

            </div>
          ))
      }
     
    </div>
  );
}

export default VacanciesPage;
