import React from 'react';
import HomePage from './main/HomePage';
import ServicesPage from './main/ServicesPage';
import ContactPage from './main/ContactPage';
import VacanciesPage from './main/VacanciesPage';

function Main({ currentPage }) {
  return (
    <main>
      {currentPage === 'HOME' && <HomePage />}
      {currentPage === 'SERVICES' && <ServicesPage />}
      {currentPage === 'CONTACT' && <ContactPage />}
      {currentPage === 'VACANCIES' && <VacanciesPage />}
    </main>
  );
}

export default Main;