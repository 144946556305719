import React from 'react';

function Footer() {
  return (
    <footer>
      <div>Contact Info</div>
      <div>Telephone: +1(914)481-3946</div>
      <div>Email: admin@blockbounce.org</div>
      <div>Location: 13th Street 47 W, New York, NY 10011</div>
    </footer>
  );
}

export default Footer;