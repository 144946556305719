import React from 'react';

function ServicesList({ services }) {
  return (
    <div className = "text">
      <h2 style = {{textAlign:'center'}}>Our Services</h2>
      <ul>
        {services.map((service, index) => (
          <li style = {{marginTop: 24}} key={index}>{service}</li>
        ))}
      </ul>
    </div>
  );
}

export default ServicesList;