import React, { useState } from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';

function ContactPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    comment: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const createNotification = (type) => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success!', 'You submitted.');
          break;
        case 'warning':
          NotificationManager.warning('', 'Please fill out all information.', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
  };

  const isFormDataValid = () => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (formData[key] === "" || formData[key] === null) {
          return false; // If any field is empty or null, return false
        }
      }
    }
    return true; // If all fields are filled, return true
  };

  const handleContact = () => {
    console.log(isFormDataValid())
    if (isFormDataValid()) {
      createNotification('success');
      setFormData({
      firstName: '',
      lastName: '',
      email: '',
      comment: ''
    });
    } else {
      createNotification('warning');
    }
  };



  return (
    <div className = "homeContent">
      <div className = "homebody">
        <p className = "text-center"><h1>Contact Us</h1></p>
        <div className = "mt24">
           <p>*Name</p>
           <div className = "flex">
            <input placeholder = "First" type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} />
            <input placeholder = "Last" type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} />
           </div>
        </div>
        <div className = "mt24">
          <p>*Email</p>
          <div className = "flex">
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
          </div>
        </div>
        <div className = "mt24">
            <p>*Comment</p>
            <div className = "flex">
              <textarea rows="4" id="comment" name="comment" value={formData.comment} onChange={handleChange}></textarea> 
            </div>
        </div>
          <button className = "mt24" onClick = {() => {handleContact()}}>Submit</button>
      </div>
      <NotificationContainer/>
    </div>
  );
}

export default ContactPage;