import React from 'react';

function Header({ currentPage, handleTabChange }) {
  return (
    <header>
    <div className="logo" >
      <img src="logo.png" alt="Company Logo" style={{ width: '50px', height: '50px' }}  />
      <p style = {{paddingLeft:8, fontSize: 24}} >
        <span className = "custom-yellow"> Block</span><span>Bounce</span>
      </p>
    </div>
    <div style = {{fontSize:12}}>EMPOWERING INNOVATION, CONNECTING TOMORROW.</div>
      <nav >
        <ul style = {{display: 'flex'}}>
          <li className = 'flexGrow' > <div className = "line"></div></li> 
          <li className={currentPage === 'HOME' ? 'active' : ''} onClick={() => handleTabChange('HOME')}>HOME</li>
          <li className={currentPage === 'SERVICES' ? 'active' : ''} onClick={() => handleTabChange('SERVICES')}>SERVICES</li>
          <li className={currentPage === 'CONTACT' ? 'active' : ''} onClick={() => handleTabChange('CONTACT')}>CONTACT</li>
          <li className={currentPage === 'VACANCIES' ? 'active' : ''} onClick={() => handleTabChange('VACANCIES')}>VACANCIES</li>
          <li className = 'flexGrow' ><div className = "line"></div></li> 
        </ul>
      </nav>
    </header>
  );
}

export default Header;