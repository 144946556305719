
import React, { useState, useEffect } from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';


function Home() {
  const [currentPage, setCurrentPage] = useState('HOME');

  const handleTabChange = (tab) => {
    setCurrentPage(tab);
  };

  return (
      <div >
        <Header currentPage={currentPage} handleTabChange={handleTabChange} />
        <Main currentPage={currentPage} />
        <Footer />
      </div>
    
  );
}

export default Home;
