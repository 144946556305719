import React from 'react';
import {Link} from 'react-router-dom'

function JobHeader() {
  return (
    <div className = "flex center bg-normal">
      <div className = "flex space center w-7">
        <div className="logo" >
          <img src="logo.png" alt="Company Logo" style={{ width: '50px', height: '50px' }}  />
          <p style = {{paddingLeft:8, fontSize: 24}} >
            <span className = "custom-yellow"> Block</span><span>Bounce</span>
          </p>
        </div>
        <div className = "flex center">
          <Link to = "/"> / HOME /</Link>
        </div>
      </div>
    </div>
  );
}

export default JobHeader;