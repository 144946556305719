
import React, { useState, useEffect } from 'react';
import JobHeader from '../components/JobHeader';
import {NotificationContainer, NotificationManager} from 'react-notifications';


function Job1() {
  const [applied, setApply] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    experience: 0,
    city: "",
    email: "",
    phone: "",
    resume: null
  })
  const handleFileChanging = (e) => {
     const file = e.target.files[0];
    // Check if the selected file type is allowed
    if (file && isValidFileType(file.type)) {
       setFormData({...formData, resume:e.target.files[0]})
    } else {
      // Notify the user or handle the invalid file type
      alert("Please select a DOC, PDF, RTF, or TXT file.");
    }
   
  };

  const isValidFileType = (fileType) => {
    return (
      fileType === "application/msword" || // DOC
      fileType === "application/pdf" ||    // PDF
      fileType === "application/rtf" ||    // RTF
      fileType === "text/plain"            // TXT
    );
  };

 const createNotification = (type) => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success!', 'You submitted.');
          break;
        case 'warning':
          NotificationManager.warning('', 'Please fill out all information.', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
  };

  const isFormDataValid = () => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (formData[key] === "" || formData[key] === null) {
          return false; // If any field is empty or null, return false
        }
      }
    }
    return true; // If all fields are filled, return true
  };

  const handleSubmit = () => {
    if (isFormDataValid()) {
      createNotification('success');
    } else {
      createNotification('warning');
    }
  };

  return (
    <div>
      <JobHeader />
      <div className = "flex center ">
        <div className = "w-7 flex">
          <div className = "w-7 job-card">
          {
            applied ? (
              <div>
                <p className = "text-title">Web 3 VP of marketing (Equity Cofounder)</p>
                <p>[REMOTE]</p>

                <div className = "flex mt16">
                  <div className = "w-5">
                    <p className = "text-subtitle">First Name *</p>
                    <input placeholder = "First" type="text" id="firstName" name="firstName" value={formData.firstName} onChange={ (e) => {setFormData({...formData, firstName: e.target.value})} } />
                  </div>
                  <div className = "w-5">
                    <p className = "text-subtitle">Last Name *</p>
                    <input placeholder = "Last" type="text" id="lastName" name="lastName" value={formData.lastName} onChange={ (e) => {setFormData({...formData, lastName: e.target.value})} } />
                  </div>
                </div>

                <div className = "flex mt16">
                  <div className = "w-5">
                    <p className = "text-subtitle">Experience *</p>
                    <select id="experience" name="experience" value={formData.experience} onChange={ (e) => {setFormData({...formData, experience: e.target.value})} }>
                      <option value="1">0-1 Year</option>
                      <option value="2">1-2 Years</option>
                      <option value="3">2-3 Years</option>
                      <option value="4">3-4 Years</option>
                      <option value="5">4-5 Years</option>
                      <option value="6">5-6 Years</option>
                      <option value="7">6-7 Years</option>
                      <option value="8">7-8 Years</option>
                      <option value="9">8-9 Years</option>
                      <option value="10">9-10 Years</option>
                      <option value="11">10+ Years</option>
                    </select>
                  </div>
                  <div className = "w-5">
                    <p className = "text-subtitle">City *</p>
                    <input placeholder = "City" type="text" id="city" name="city" value={formData.city} onChange={ (e) => {setFormData({...formData, city: e.target.value})} } />
                  </div>
                </div>

                <div className = "flex mt16">
                  <div className = "w-5">
                    <p className = "text-subtitle">Email *</p>
                    <input placeholder = "Email" type="email" id="email" name="email" value={formData.email} onChange={ (e) => {setFormData({...formData, email: e.target.value})} } />
                  </div>
                  <div className = "w-5">
                    <p className = "text-subtitle">Phone *</p>
                    <input placeholder = "Phone" type="tel" id="phone" name="phone" value={formData.phone} onChange={ (e) => {setFormData({...formData, phone: e.target.value})} } />
                  </div>
                </div>
                <p> <span Resume className = "text-subtitle" >Resume * </span><span>-Must be in Doc, Docx, PDF. (less than 8MB)</span></p>
                <input type="file" accept=".doc,.pdf,.rtf,.txt"  onChange={handleFileChanging} />

              </div>
              ) : (

              <>
                <p className = "text-title"> Web 3 VP of marketing (Equity Cofounder)</p>
                <p>[ONSITE]  13 May 2024</p>

                <p className = "text-subtitle">PART-TIME CO-FOUNDER (EQUITY COMPENSATION ONLY)</p>
                <p className = "text-subtitle"> Join Our Visionary Team</p>
                <p>We are excited to offer a unique opportunity to join us as an equity co-founder at GO WFO, a pioneering venture that merges technology with creativity. This role places you at the heart of the convergence between the creator economy and the extreme sports market through our innovative Autheo Layer-1 blockchain platform.</p>
                <p className = "text-subtitle">What We Offer</p>
                <p>As a part-time co-founder, you will:</p>
                <ul>
                  <li>
                    Collaborate with a team of seasoned entrepreneurs who have successfully launched ventures nationally and internationally, raised capital exceeding $500 million, and spearheaded several high-revenue disruptive projects.
                  </li>
                  <li>
                    While this is initially an equity-only role, our comprehensive tokenomics plan includes offering tokens to co-founders upon completion. Following our anticipated public token sale within the next six months, we plan to introduce salaries for both full-time and part-time positions.
                  </li>
                  
                </ul>
                <p>Future Compensation Plans</p>
                <p>Future Compensation Plans</p>
                <p className = "text-subtitle">About the Organization</p>
                <p>GO WFO has been under development for over two years. We are gearing up to launch:</p>
                <ul>
                  <li>Autheo: Our Layer-1 blockchain platform tailored for creators to own, expand, and monetize their networks.</li>
                  <li>GO WFO: The first Layer-2 Creator Network focused on extreme sports operating on the Autheo platform.</li>
                </ul>
                <p>These initiatives are set to transform the creator industry, projected to reach $500 billion by 2027, and the extreme sports market, an exciting sports sector with over 400 million active partipants worldwide. </p>
                <p className = "text-subtitle">ROLE:  VP OF MARKETING</p>
                <p>The Vice President of Marketing will be responsible for executing holistic marketing strategies and tactics regarding the marketing and outreach initiatives for Autheo and GO WFO projectxs, creating our strategic marketing plan, optimizing brand engagement, and fostering relationships with in the creator and extreme sports industries The ideal candidate will have a proven expertise in strategic marketing, growth hacking, content marketing, community engagement, and a deep passion for the creator industry, backed by strong leadership and analytical capabilities.</p>
                <p className = "text-subtitle">RESPONSIBILITIES: </p>
                <ul>
                  <li>Develop marketing strategy and execution plan for launching and growing the GO WFO community network and ecosystem.</li>
                  <li>Help orchestrate and implement comprehensive marketing strategies to fuel GO WFO's growth trajectory.</li>
                  <li>Spearhead branding initiatives, crafting compelling messaging that resonates with our target audience.</li>
                  <li>Responsible for creating compelling narratives around individual products and services.</li>
                  <li>Develop and optimize the GO WFO website, community network and landing pages to bolster lead generation and conversion rates.</li>
                  <li>Curate persona development strategies, fine-tuning our messaging to resonate with specific audience segments.</li>
                </ul>
                <p className = "text-subtitle">REQUIREMENTS:</p>
                <ul>
                  <li>Proven track record in leading comprehensive marketing campaigns with measurable results.</li>
                  <li>Expertise in growth hacking, with a knack for identifying and leveraging growth opportunities.</li>
                  <li>Deep understanding of persona development and customer journey mapping.</li>
                  <li>Proficiency in content marketing, inbound, and outbound marketing techniques.</li>
                  <li>Develop and optimize the GO WFO website, community network and landing pages to bolster lead generation and conversion rates.</li>
                  <li>Strong analytical skills with a penchant for data-driven decision-making.</li>
                </ul>
                <p className = "text-subtitle">INNOVATIVE GROWTH & ASPIRATIONS:</p>
                <p>We're at the frontier of Web3 technology adoption, integrating a community network with innovative elements like blockchain and NFTs to redefine the industry's interaction landscape.</p>

                <button onClick = {() => setApply(true)} >Apply</button>
                </>

              )
          }
          
          </div>
          <div className = "w-2 job-card">
            <div className = 'flex'>
            {
              applied ? (
                <button className = "flexGrow" onClick = {handleSubmit} >Submit</button>
                ) : (
                <button className = "flexGrow" onClick = {() => setApply(true)} >Apply</button>
                )  
            }
            </div>
            <p className = "text-subtitle">Director/ Vice-President</p>
            <p className = "text-subtitle">Part Time</p>
            <p className = "text-subtitle">[Remote]</p>
          </div>
        </div>
      </div>

      <NotificationContainer/>
    </div>
  );
}

export default Job1;
