
import React, { useState } from 'react';

function HomePage() {


  return (
    <div className = "homeContent">
      <div className = "text">
        <p>
          Blockchain technology is at the start of where the internet was in 1993. It took time for the Dot-com winners to emerge and there shouldn’t be any expectation that it will be easier for today’s blockchain innovators to create robust commercial success. There will be leaders who win and others who lose.
          
        </p>
        <p>
          The potential for blockchain can only be realized with those leaders who create the best and most unique uses of a technology that, if done correctly, can significantly change the economics of a company… possibly an industry.
          
        </p>
        <p>
          Does the blockchain innovation solve an existing problem? Does the innovation save time and/or money? In fact, can the utilization of blockchain provide an idea so noteworthy as to alter the entire landscape? In our opinion, the answers are YES!
          
        </p>
        <p>
          The executive team at Block Bounce (BB) believes it has identified several promising opportunities within the fitness ecosystem that can benefit from the introduction of blockchain based solutions which will surely add a “bounce” to the bottom line for those many companies that simply don’t know how or where to begin.
          
        </p>
      </div>
    </div>
  );
}

export default HomePage;
