import logo from './logo.svg';
import 'react-notifications/lib/notifications.css';
import './App.css';

import React, { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './Home';
import Job1 from './jobs/Job1';


function App() {

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/job1",
    element: <Job1 />,
  },
]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
