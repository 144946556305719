import React from 'react';
import ServicesList from '../components/ServicesList'; // Import the ServicesList component

function ServicesPage() {
  const services = [
    "Business consulting services in the field of blockchain systems",
    "Advertising and marketing services provided by means of indirect methods of marketing communications, namely, social media, search engine marketing, inquiry marketing, internet marketing, mobile marketing, and other forms of passive, sharable or viral communications channels",
    "Promotional services, namely, promoting the services of others by means of providing online digital objects and related blockchain decentralized applications",
    // Add more services as needed
  ];

  return (
    <div className = "homeContent">
      <ServicesList services={services} />
    </div>
  );
}

export default ServicesPage;